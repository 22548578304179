.header-dark {
  background-size: cover;
  padding-bottom: 80px;
}

@media (min-width:768px) {
  .header-dark {
    padding-bottom: 120px;
  }
}

.header-dark .navbar {
  background: transparent;
  color: #fff;
  border-radius: 0;
  box-shadow: none;
  border: none;
}

@media (min-width:768px) {
  .header-dark .navbar {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }
}

.header-dark .navbar .navbar-brand {
  font-weight: bold;
  color: inherit;
}

.header-dark .navbar .navbar-brand:hover {
  color: #f0f0f0;
}

.header-dark .navbar .navbar-collapse span {
  margin-top: 5px;
}

.header-dark .navbar .navbar-collapse span .login {
  color: #d9d9d9;
  margin-right: .5rem;
  text-decoration: none;
}

.header-dark .navbar .navbar-collapse span .login:hover {
  color: #fff;
}

.header-dark .navbar .navbar-toggler {
  border-color: #747474;
}

.header-dark .navbar .navbar-toggler:hover, .header-dark .navbar-toggler:focus {
  background: none;
}

.header-dark .navbar .navbar-toggler {
  color: #eee;
}

.header-dark .navbar .navbar-collapse, .header-dark .navbar form {
  border-color: #636363;
}

@media (min-width: 992px) {
  .header-dark .navbar.navbar .navbar-nav .nav-link {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }
}

.header-dark .navbar.navbar-dark .navbar-nav .nav-link {
  color: #d9d9d9;
}

.header-dark .navbar.navbar-dark .navbar-nav .nav-link:focus, .header-dark .navbar.navbar-dark .navbar-nav .nav-link:hover {
  color: #fcfeff !important;
  background-color: transparent;
}

.header-dark .navbar .navbar-nav > li > .dropdown-menu {
  margin-top: -5px;
  box-shadow: 0 4px 8px rgba(0,0,0,.1);
  background-color: #fff;
  border-radius: 2px;
}

.header-dark .navbar .dropdown-menu .dropdown-item:focus, .header-dark .navbar .dropdown-menu .dropdown-item {
  line-height: 2;
  font-size: 14px;
  color: #37434d;
}

.header-dark .navbar .dropdown-menu .dropdown-item:focus, .header-dark .navbar .dropdown-menu .drodown-item:hover {
  background: #ebeff1;
}

.header-dark .navbar .action-button, .header-dark .navbar .action-button:active {
  background: #208f8f;
  border-radius: 20px;
  font-size: inherit;
  color: #fff;
  box-shadow: none;
  border: none;
  text-shadow: none;
  padding: .5rem .8rem;
  transition: background-color 0.25s;
}

.header-dark .navbar .action-button:hover {
  background: #269d9d;
}

.header-dark .navbar label {
  color: #ccc;
}

.header-dark .navbar form .search-field {
  display: inline-block;
  width: 80%;
  background: none;
  border: none;
  border-bottom: 1px solid transparent;
  border-radius: 0;
  color: #ccc;
  box-shadow: none;
  color: inherit;
  transition: border-bottom-color 0.3s;
}

.header-dark .navbar form .search-field:focus {
  border-bottom: 1px solid #ccc;
}

.header-dark .hero {
  margin-top: 60px;
}

@media (min-width:768px) {
  .header-dark .hero {
    margin-top: 20px;
  }
}

.header-dark .hero h1 {
  color: #fff;
  font-family: 'Bitter', serif;
  font-size: 40px;
  margin-top: 20px;
  margin-bottom: 80px;
}

@media (min-width:768px) {
  .header-dark .hero h1 {
    margin-bottom: 50px;
    line-height: 1.5;
  }
}

.header-dark .hero .ratio iframe {
  background-color: #666;
}

