@font-face {
	font-family: 'Helvetica';
	src: url(../../assets/fonts/Helvetica.eot) format('embedded-opentype'),
	url(../../assets/fonts/Helvetica.eot) format('embedded-opentype'),
	url(../../assets/fonts/Helvetica.woff2) format('woff2'),
	url(../../assets/fonts/Helvetica.woff) format('woff'),
	url(../../assets/fonts/Helvetica.ttf) format('truetype'),
	url(../../assets/fonts/Helvetica.svg) format('svg');
	font-weight: 400;
	font-style: normal;
}